import React from 'react';
import EditIconAtom from 'atoms/EditIcon'

import {Box, Button, Text} from 'grommet'
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage'

const COLUMNS=[
    {
      Header: 'ACTION',
      width: 120,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box pad={{ left: 'medium' }}>
            <Button
              onClick={() => {
                GenericWindowPostMessage('OPEN_TIME_OFF_REQUEST_SLIDER', {
                  empName: row.original.employee_name,
                  policyId: row.original.id,
                });
              }}
              title="Edit Time Off Request"
              disabled={row?.original?.policy_type==='PAID_HOLIDAYS'}
              // disabled={row.original.status !== 'PENDING' ? true : false}
            >
              <EditIconAtom height="15" width="15" />
            </Button>
          </Box>
        );
      },
    },
    {
      Header: 'DATE',
      accessor: 'start_date',
      id: 'start_date',
      width: 160,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box justify="center">
            <Text>{row.original.start_date}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'POLICY',
      accessor: 'policy_name',
      id: 'policy_name',
      width: 200,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box>
            <Text>{row.original.policy_name}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'TOTAL HOURS',
      accessor: 'total_requested_hours',
      id: 'total_requested_hours',
      width: 145,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box justify="center">
            <Text>{row.original.total_requested_hours}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      id: 'status',
      sortable: false,
      width: 120,
      resizable: false,
      Cell: row => {
        return (
          <Box justify="center">
            <Text>{row.original.status}</Text>
          </Box>
        );
      },
    },
  ]


export default COLUMNS