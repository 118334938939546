// Core imports
import GraniteError from 'utils/granite-error';
import GraniteAPIClient from 'utils/granite-client';

// Application imports
import Companies from 'company/entities/company';
import Date from 'company/entities/date';

const url = process.env.REACT_APP_WORKSANA_BASE_URL;
/**
 *  department api call implementation.
 *
 *
 * @returns {object} department.
 * @throws {GraniteError} api failed.
 */

async function fetchCompanies(payload) {
  const { company_id, ...params } = payload;
  try {
    const { data } = await GraniteAPIClient('', { company: company_id }).get('/company/all/', {
      params,
    });
    const companies = data.map(items => new Companies(items));
    // TODO: update it while switching to granite api
    return { companies };
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

async function getBasicSettings(payload) {
  try {
    if (payload?.authToken) {
      const { data } = await GraniteAPIClient(url, {
        Authorization: `Bearer ${payload?.authToken}`,
        company: payload?.company_id,
      }).get('company/settings/base/');
      const responseDate = {
        dateFormat: data.date_format,
        dateFormatDisplay: data.date_format_display,
        timeFormat24Hrs: data.time_format_24_hrs,
      };
      const date = new Date(responseDate);
      return date;
    } else {
      const { data } = await GraniteAPIClient('', { company: payload?.company_id }).get('company/settings/base/');
      const responseDate = {
        dateFormat: data?.date_format,
        dateFormatDisplay: data?.date_format_display,
        timeFormat24Hrs: data?.time_format_24_hrs,
      };
      const date = new Date(responseDate);
      return date;
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

export default {
  fetchCompanies,
  getBasicSettings,
};
